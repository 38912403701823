#root, html,body {
  margin: 0;
 width: 100%;
 height: 100%;
 padding: 0;
}


* {
  padding: 0;
  margin: 0;
}