@import '../../styles/shared.scss';

.menu-hidden {
	display: none;
}

.menu {
	height: 100%;
	width: 100%;
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.9);
	overflow-x: hidden;

	.menu-container {
		width: 100%;
		height: 100%;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.menu-button {
			font-size: 36px;
			color: $smokeTeal;
			margin-bottom: 20px;
		}
		.menu-navigation-list {
			margin-top: 20px;
			text-align: left;
			li {
				margin: 5px auto;
				a,
				a:active,
				a:visited {
					font-size: 30px;
					text-decoration: none;
					color: $grey;
					i {
						color: $smokeTeal;
						width: 40px;
						font-size: 24px;
					}
				}
			}
		}
	}
}

.fade-appear {
	opacity: 0;
	z-index: 1;
}
.fade-appear.fade-appear-active {
	opacity: 1;
	transition: opacity 500ms linear;
}
.fade-in {
	opacity: 1;
	transition: opacity 500ms linear;
}
.fade-enter {
	opacity: 0;
	z-index: 1;
}
.fade-enter.fade-enter-active {
	opacity: 1;
	transition: opacity 500ms linear;
}
// .fade-exit {
// 	opacity: 1;
// }
// .fade-exit.fade-exit-active {
// 	opacity: 0;
// 	transition: opacity 3000ms linear;
// }
// .fade-exit-done {
// 	opacity: 0;
// }
