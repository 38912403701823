@import '../../styles/shared.scss';

.header {
	width: 90%;
	height: 180px;
	color: $teal;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: auto;

	.menu-button {
		font-size: 26px;
		color: $smokeTeal;
		cursor: pointer;
	}

	.header-image {
		width: 200px;
		margin: auto 50px;
		padding-top: 20px;
	}

	.social-list {
		list-style-type: none;

		display: flex;

		.social-list-item {
			height: 20px;
			img {
				height: 30px;
				margin: auto 3px;
			}
		}
	}
}

@media only screen and (max-width: 460px) {
	.header {
		width: 100%;
		height: 130px;
		color: $teal;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto;

		.menu-button {
			font-size: 24px;
			font-weight: 500;
			background-color: black;
		}

		.header-image {
			width: 120px;
			margin: 10px 0;
			padding-top: 0;
		}

		// .social-list {
		// 	display: none;
		// }
	}
}
